import React, { Component,lazy } from "react";
//import Header from "./Header";
import MovieList from "./MovieList";

import { BrowserRouter, Route } from "react-router-dom";

//import Home from "./Home";
//import MovieDetails from "./MovieDetails";
import loadable from '@loadable/component'
 const HomePage   = loadable(() =>  import('../pages/HomePage'));
 const PostDetailPage   = loadable(() =>  import('../pages/PostDetailPage'));
const StoryPage  = loadable(() =>  import('../pages/StoryPage'));
const CreateContentPage  = loadable(() =>  import('../pages/admin/CreateContentPage'));

//import Home from "./Home";

//const MovieDetails = loadable(() => import('./MovieDetails'))
const MovieDetails = lazy(() => import('./MovieDetails'))
 

class App extends Component {
  state = { message: "Hello, React!!!" };
  render() {
    return (
      <BrowserRouter>
      
          {/*<Header />*/}
             <Route path="/" exact={true} component={HomePage} />

             <Route path="/admin/content/create/:seoId" exact={true} component={CreateContentPage} />
             <Route path="/admin/content/create" exact={true} component={CreateContentPage} />

                <Route path="/story" exact={true} component={StoryPage} />
             <Route path="/:seoUrl" exact={true} component={PostDetailPage} />
            <Route path="/movies" exact={true} component={MovieList} />
            <Route path="/details" exact={true} component={MovieDetails} />
            

            
         
      </BrowserRouter>
    );
  }
}

export default App;
