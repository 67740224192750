
import React from 'react';
import ReactDOM from 'react-dom'; 
import App from './components/App';
import { compose,createStore,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
 
import { Provider } from 'react-redux';
 
import  {BrowserRouter }  from 'react-router-dom';
import  { composeWithDevTools } from 'redux-devtools-extension';
import { hydrate, render } from "react-dom";

import reducers  from './reducers';
const allEnhancers = compose(
	applyMiddleware(thunk)
);
const store=createStore(reducers,allEnhancers);
//store.dispatch( );
const rootElement = document.getElementById("root");


if (rootElement.hasChildNodes()) {
	hydrate( <Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, rootElement);
  } else {
	ReactDOM.render(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider> ,rootElement);

  }

