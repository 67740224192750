const initialState = {
    fetching: false,
    tiktokItems:[],
    currentPageData:null,
    currentOnCreatePageData:null
   
  };
  
  
  const tiktokReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_CONTENT': {
      
        return {
          ...state,
        tiktokItems: action.payload 
        };
  
      }
       case 'SET_CONTENT_BY_SEO': {
          
        console.info("SET_CONTENT_BY_SEO reducer" + action.payload);
  
        return {
          ...state,
        currentPageData: action.payload 
        };
  
      }
      case 'SET_CONTENT_ON_CREATE': {
          
        console.info("SET_CONTENT_ON_CREATE reducer" + action.payload);
  
        return {
          ...state,
          currentOnCreatePageData: action.payload 
        };
  
      }
      
      
  
      default:
        return state;
    }
  }
  export default tiktokReducer;
  